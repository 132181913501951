import { useSelector }                  from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Pagination                       from '../../../components/Pagination/Pagination'
import NewsGridViewItem                 from './NewsGridViewItem'

export default function NewsGridView( props: any ): JSX.Element {
  const { refetch }      = props
  const [ searchParams ] = useSearchParams()
  const navigate: any    = useNavigate()
  const readIds: any     = []
  const pagination: any  = useSelector( ( state: any ) => state.news.pagination )
  const news: any        = useSelector( ( state: any ) => state.news.data )

  // Extract values from the object into an array
  let newsArray = Object.values(news);

  // Sort the array based on the publish date, falling back to created_at if publish is null or empty
  newsArray.sort( ( a: any, b: any ): number => {
    const aDate: any = a.publish ?? a.created_at
    const bDate: any = b.publish ?? b.created_at
    return ( aDate < bDate ) ? 1 : ( ( aDate > bDate ) ? -1 : 0 )
  } )

  // Extract the latest news article
  const latestNews: any = newsArray[ 0 ]

  // Remove the most recent news article from the array
  newsArray.shift()

  const setCurrentPage = ( page: number = 1 ) => navigate( {
                                                             pathname: '/news',
                                                             search:   `?query=${ searchParams.get( 'query' ) }&page=${ page }`
                                                           } )

  return (
    <div className="NewsGridView">
      { news.length < 1 && <p>There is no News yet.</p> }
      <div className="space-y-3">

        { latestNews &&
          <div className="mb-8">
            <NewsGridViewItem
              featured
              key={ latestNews.id }
              isNew={ readIds && ( readIds.length === 0 || !readIds.includes( latestNews.id ) ) }
              itemDetails={ latestNews }
              refetch={ refetch }
            />
          </div>
        }

        { newsArray.length > 0 &&
          <div className="flex flex-col">
            <div className="NewsGridView__grids">
              { newsArray.map( ( item: any ) =>
                                 <NewsGridViewItem
                                   key={ item.id }
                                   isNew={ readIds && ( readIds.length === 0 || !readIds.includes( item.id ) ) }
                                   itemDetails={ item }
                                   refetch={ refetch }
                                 /> ) }
            </div>
          </div>
        }
      </div>


      { ( pagination?.totalPages > 0 ) &&
        <Pagination
          className="justify-content-center"
          currentPage={ pagination.page }
          totalCount={ pagination.totalResults }
          pageSize={ pagination.perPage }
          onPageChange={ ( page: any ) => setCurrentPage( page ) }
        />
      }
    </div>
  )
}
