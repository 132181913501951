import NewsForm           from './NewsForm'
import MainLayout          from '../../layouts/MainLayout'

export default function AddNews(): JSX.Element {
  return (
    <MainLayout>
      <div className="max-w-screen-3xl mx-auto w-full">
        <div className="flex-grow lg:p-10 p-3 lg:space-y-8 space-y-3">

          <div className="flex flex-col items-center w-full bg-white rounded-xl p-4 lg:p-5">
            <div className="flex w-full mb-4">
              <header className="mb-4 w-full">
                <h1 className="sm:text-2xl text-xl font-bold">New News</h1>
              </header>
            </div>

            <div className="w-full">
              <NewsForm />
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
