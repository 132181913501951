import MainLayout from '../../layouts/MainLayout'

export default function SuppliersFeedback(): JSX.Element {
  const iframeStyles: any = {
    border: 0,
    boxShadow: '5px 5px 56px 0px rgba(0,0,0,0.25)'
  };

  const iframeWrapperStyles: any = {
    textAlign: 'center',
    minHeight: '600px',
    height: '100vh'
  }

  return (
    <MainLayout>
      <div className="max-w-screen-3xl mx-auto w-full">
        <div className="flex-grow lg:p-10 p-3 lg:space-y-8 space-y-3">

          <div className="flex flex-col items-center w-full bg-white rounded-xl p-4 lg:p-5">
            <div className="flex w-full mb-4">
              <header className="mb-4">
                <h1 className="sm:text-2xl text-xl font-bold">Supplier Feedback</h1>
              </header>
            </div>

            <div className="flex flex-col w-full">
              <div style={iframeWrapperStyles}>
                <iframe
                  src="https://forms.monday.com/forms/embed/c05c37c2243f147b50b41cb035e673cc?r=use1"
                  width={'100%'}
                  height={'100%'}
                  style={iframeStyles}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
