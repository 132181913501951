import NewsForm            from './NewsForm'
import { useQuery }        from '@apollo/client'
import { useState }        from 'react'
import { useParams }       from 'react-router-dom'
import { LoadingEllipsis } from '../../components/Loaders/Loaders'
import { GET_NEWS_BY_UID } from '../../graphql/news'
import MainLayout          from '../../layouts/MainLayout'

export default function EditNews(): JSX.Element {
  const { unique_id }      = useParams()
  const [ news, setNews ]  = useState( null )
  const { loading, error } = useQuery( GET_NEWS_BY_UID, {
    variables:   { input: { unique_id } },
    onCompleted: ( { newsByUid } ) => setNews( newsByUid )
  } )

  return (
    <MainLayout>
      <div className="max-w-screen-3xl mx-auto w-full">
        <div className="flex-grow lg:p-10 p-3 lg:space-y-8 space-y-3">

          { loading && <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4" /> }

          { !loading && news &&
            <div className="flex flex-col items-center w-full bg-white rounded-xl p-4 lg:p-5">
              <div className="flex w-full mb-4">
                <header className="mb-4">
                  <h1 className="sm:text-2xl text-xl font-bold">Edit News</h1>
                  <p>{ news.title }</p>
                </header>
              </div>

              <div className="w-full">
                <NewsForm news={ news } />
              </div>
            </div>
          }

          {
            error &&
            <div className="flex flex-col">
              <b>Error occurred when fetching single news:</b> { ' ' }
              { error.graphQLErrors.map( ( { message }, i: number ) => (
                <span key={ i }>{ message }</span>
              ) ) }
            </div>
          }
        </div>
      </div>
    </MainLayout>
  )
}
