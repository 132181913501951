// @ts-nocheck
import { useMutation }                                                       from '@apollo/client'
import { XCircleIcon }                                                       from '@heroicons/react/20/solid'
import { TrashIcon }                                                         from '@heroicons/react/24/outline'
import { format, parseISO }                                                  from 'date-fns'
import { UploadCloud }                                                       from 'lucide-react'
import { useEffect, useRef, useState }                                       from 'react'
import Dropzone                                                              from 'react-dropzone'
import { useDispatch, useSelector }                                          from 'react-redux'
import { useNavigate }                                                       from 'react-router-dom'
import Datepicker                                                            from 'react-tailwindcss-datepicker'
import { Button, TextField }                                                 from '../../components'
import { Editor }                                                            from '../../components/Editor/Editor'
import { Image }                                                             from '../../components/Image'
import { LoadingEllipsis }                                                   from '../../components/Loaders/Loaders'
import { Switch }                                                            from '../../components/Switch'
import { setNewsRefetch }                                                    from '../../features/news/newsSlice'
import { setToasts, showToaster }                                            from '../../features/toaster/toasterSlice'
import { CREATE_NEWS, DELETE_NEWS, DELETE_NEWS_FEATURED_IMAGE, UPDATE_NEWS } from '../../graphql/news'
import { assetFilePath }                                                     from '../../utilities'
import Api                                                                   from '../../utilities/axios'

export default function NewsForm( props: any ): JSX.Element {
  const { news }                        = props
  const navigate: any                   = useNavigate()
  const dispatch: any                   = useDispatch()
  const currentTenant                   = useSelector( ( state ) => state.currentTenant.tenant )
  const uploadApiUrl: string            = process.env.REACT_APP_UPLOAD_HOST
  const appCdnUrl: string               = process.env.REACT_APP_CDN + ( process.env.REACT_APP_CDN.slice( -1 ) === '/' ? '' : '/' )
  const featurePreviewContainer         = useRef<HTMLElement | null>( null )
  const [ fileChanged, setFileChanged ] = useState<boolean>( false )
  const [ loading, setLoading ]         = useState<boolean>( false )

  const [ updateNews, { loading: updating } ] = useMutation( UPDATE_NEWS, {
    onCompleted: () => {
      dispatchMessage( 'success', 'News saved' )
      dispatch( setNewsRefetch( true ) )
    }
  } )

  const [ createNews, { loading: creating } ] = useMutation( CREATE_NEWS, {
    onCompleted: ( { createNews } ) => {
      dispatchMessage( 'success', 'News created' )
      dispatch( setNewsRefetch( true ) )
      navigate( `/news/${ createNews.data.unique_id }/edit` )
    }
  } )

  const [ deleteNewsFeaturedImage, { loading: deletingFeaturedImage } ] = useMutation( DELETE_NEWS_FEATURED_IMAGE, {
    onCompleted: () => handleImageDeleteSuccess(),
    onError:     () => dispatchMessage( 'error', 'Image not deleted. Please try again' )
  } )

  const [ deleteNews, { loading: deleting } ] = useMutation( DELETE_NEWS, {
    onCompleted: () => {
      dispatchMessage( 'success', 'News deleted' )
      dispatch( setNewsRefetch( true ) )
      navigate( '/news' )
    }
  } )

  const [ publish, setPublish ]                   = useState( news ? !news.draft : false )
  const [ title, setTitle ]                       = useState( news?.title ?? '' )
  const [ headline, setHeadline ]                 = useState( news?.headline ?? '' )
  const [ body, setBody ]                         = useState( news?.body ?? '<p></p>' )
  const [ featuredImageUrl, setFeaturedImageUrl ] = useState( news?.featured_image_url ?? '' )
  const [ file, setFile ]                         = useState( null )
  const [ publishDate, setPublishDate ]           = useState( {
                                                                startDate: news?.publish ?? null,
                                                                endDate:   news?.publish ?? null
                                                              } )
  const [ expireDate, setExpireDate ]             = useState( {
                                                                startDate: news?.expire ?? null,
                                                                endDate:   news?.expire ?? null
                                                              } )

  const handleSetFile = ( newValue: any ) => {
    setFile( newValue )
    setFileChanged( true )
  }

  const handleSubmit = async (): Promise<void> => {
    if ( !title.length || !headline.length || !body.length ) return

    const formData: any = {
      body:               body,
      draft:              !publish,
      expire:             expireDate.startDate ? new Date( expireDate.startDate ).toISOString() : null,
      featured_image_url: featuredImageUrl,
      headline:           headline,
      publish:            publishDate.startDate ? new Date( publishDate.startDate ).toISOString() : null,
      title:              title
    }

    let newImagePath: string = ''

    if ( news ) {
      formData.unique_id = news.unique_id

      if ( fileChanged ) {
        newImagePath                = await uploadFeaturedImage( news.unique_id )
        formData.featured_image_url = newImagePath

        setFeaturedImageUrl( newImagePath )
      }

      updateNews( { variables: { input: formData } } )
    } else {
      const { data } = await createNews( { variables: { input: formData } } )

      if ( fileChanged ) {
        newImagePath                = await uploadFeaturedImage( data.createNews.data.unique_id )
        formData.featured_image_url = newImagePath
        formData.unique_id          = data.createNews.data.unique_id

        updateNews( { variables: { input: formData } } )
      }
    }
  }

  const handleDelete = ( e: any ) => {
    e.preventDefault()

    const confirmed: boolean = window.confirm( 'Are you sure you want to delete this news?' )

    if ( confirmed ) {
      deleteNews( { variables: { input: { unique_id: news?.unique_id } } } )
    }
  }

  const handleDeleteFeaturedImage = async ( e: any ): Promise<void> => {
    e.preventDefault()

    if ( !news.featured_image_url || deletingFeaturedImage ) return

    const confirmed: boolean = window.confirm( 'Are you sure you want to delete Featured Image?' )

    if ( confirmed ) {
      deleteNewsFeaturedImage( { variables: { input: { unique_id: news?.unique_id } } } )
    }
  }

  const handleImageDeleteSuccess = (): any => {
    setFeaturedImageUrl( '' )
    handleSetFile( null )
    dispatch( setNewsRefetch( true ) )
    dispatchMessage( 'success', 'Featured Image deleted successfully' )
  }

  const dispatchMessage = ( type: string, message: string ) => {
    dispatch( setToasts( [ { id: '1', type, message } ] ) )
    dispatch( showToaster( true ) )
  }

  const uploadFeaturedImage = async ( newsId: string ): Promise<string> => {
    if ( !file ) return

    setLoading( true )

    let imagePath            = ''
    const formData: FormData = new FormData()

    formData.append( 'file', file )
    formData.append( 'is_public', true )
    formData.append( 'folder', currentTenant.uuid + '/news/feature/' + newsId )

    try {
      const { data } = await Api( uploadApiUrl, 'multipart/form-data' ).post( '/', formData )

      if ( data ) {
        dispatchMessage( 'success', 'Featured image uploaded' )
        imagePath = data[ 0 ].path
      }
    } catch ( error ) {
      console.error( 'Error uploading file:', error )
    }

    setLoading( false )

    return imagePath
  }

  const previewFile = ( file: any ): void | string => {
    if ( featurePreviewContainer.current && file?.type.startsWith( 'image/' ) ) {
      const preview: HTMLImageElement = featurePreviewContainer.current.querySelector( 'img' )
      const reader: FileReader        = new FileReader()

      reader.addEventListener( 'load', () => preview.src = reader.result, false )

      reader.readAsDataURL( file )
    }
  }

  useEffect( () => {
    if ( news && !loading ) {
      setFeaturedImageUrl( news.featured_image_url )
    }
  }, [ news, loading ] )

  return (
    <>
    { ( updating || creating || deleting || loading ) && <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4" /> }
      { ( !updating && !creating && !deleting && !loading ) &&
        <div className="mt-8 p-3">
          <div className="flex flex-wrap items-center w-full mb-3">
            <div className="w-1/3">Published</div>
            <div className="w-2/3">
              <Switch
                checked={ publish }
                onChange={ ( newValue: any ) => setPublish( newValue ) }
              />
            </div>
          </div>

          <div className="flex flex-wrap items-center w-full mb-3">
            <div className="w-1/3">Title</div>
            <div className="w-2/3">
              <TextField
                label=""
                value={ title }
                name="title"
                type="text"
                onChange={ ( newValue: any ) => setTitle( newValue ) }
                isRequired
              />
            </div>
          </div>

          <div className="flex flex-wrap items-center w-full mb-3">
            <div className="w-1/3">Headline</div>
            <div className="w-2/3">
              <TextField
                label=""
                value={ headline }
                name="headline"
                type="text"
                onChange={ ( newValue: any ) => setHeadline( newValue ) }
                isRequired
              />
            </div>
          </div>

          <div className="flex flex-wrap items-center w-full mb-3">
            <div className="w-1/3">Body</div>
            <div className="w-2/3 news-lexical">
              <Editor
                initialHtml={ body }
                actionNode={ ( content: any ) => setBody( content ) }
                isRequired
              />
            </div>
          </div>

          <div className="flex flex-wrap items-center w-full mb-3">
            <div className="w-1/3">Featured Image</div>

            { featuredImageUrl &&
              <div className="w-2/3">
                <a href={ appCdnUrl + featuredImageUrl } target="_blank">
                  <img className="h-96" src={ appCdnUrl + featuredImageUrl } alt={ title } />
                </a>
                <button
                  className="flex items-center justify-center mt-4 px-3 py-1.5 text-sm w-auto text-white font-medium rounded-full bg-red-700 hover:bg-red-500"
                  onClick={ handleDeleteFeaturedImage }
                  disabled={ deletingFeaturedImage }
                >
                  <TrashIcon className="w-4 h-4" />
                  <span>Delete Featured Image</span>
                </button>
                <p className="text-xs text-gray-500">Deleting image will remove it from the server and this article</p>
              </div>
            }

            { !featuredImageUrl &&
              <div className="w-2/3">
                <div className="cursor-pointer no-select p-6 border bg-gray-100 rounded-md">
                  <Dropzone onDrop={ ( attachedFiles ) => handleSetFile( [ attachedFiles[ 0 ] ][ 0 ] ) } multiple={ false }>
                    { ( { getRootProps, getInputProps } ) => (
                      <section>
                        <div { ...getRootProps() }>
                          <input { ...getInputProps() } />

                          <div className="flex items-center justify-center flex-col space-y-2">
                            <UploadCloud className="w-12 h-12" />
                            <p className="text-sm font-medium">Drag 'n' drop or click to select file</p>
                          </div>
                        </div>
                      </section>
                    ) }
                  </Dropzone>
                </div>

                { file &&
                  <div ref={ featurePreviewContainer } className="pb-2 border-b flex items-center flex-wrap gap-3">
                    <article title={ file.name } className="flex items-center flex-col">
                      <div className="w-10 relative h-10 rounded-md relative">
                        <button
                          title="Remove"
                          className="absolute -right-1 -top-1 bg-white shadow rounded-full"
                          onClick={ () => handleSetFile( null ) }
                        >
                          <XCircleIcon className="w-4 h-4" />
                        </button>

                        <Image
                          src={ previewFile( file ) }
                          defaultSrc={ assetFilePath( file.type.split( '/' ).pop() ) }
                        />
                      </div>

                      <small className="text-xs truncate w-12">{ file.name }</small>
                    </article>
                  </div>
                }
              </div>
            }
          </div>

          <div className="flex flex-wrap items-center w-full mb-3">
            <div className="w-1/3">Publish Date</div>
            <div className="w-2/3">
              <Datepicker
                useRange={ false }
                asSingle={ true }
                value={ publishDate }
                onChange={ ( newValue: any ) => setPublishDate( newValue ) }
              />
            </div>
          </div>

          <div className="flex flex-wrap items-center w-full mb-3">
            <div className="w-1/3">Expire Date</div>
            <div className="w-2/3">
              <Datepicker
                useRange={ false }
                asSingle={ true }
                value={ expireDate }
                onChange={ ( newValue: any ) => setExpireDate( newValue ) }
              />
            </div>
          </div>

          { news && news.notification_email && !!news.notification_date &&
            <div className="flex flex-wrap justify-end w-full mt-6 -mb-3">
              <p className="text-gray-500 text-sm text-right">
                Email notification send at { format( parseISO( news.notification_date ), 'do MMMM yyyy' ) }
              </p>
            </div>
          }

          <div className="flex flex-wrap items-center mt-6 border-t-2 border-gray-300">
            <div className="flex pt-5">
              <Button label="Submit" onClick={ handleSubmit } />

              { news &&
                <button
                  className="gap-x-2 px-3 py-1.5 text-sm w-full text-white font-medium flex items-center justify-center rounded-full text-center text-sm ml-2 bg-red-700 hover:bg-red-500"
                  onClick={ handleDelete }
                >
                  <TrashIcon className="w-4 h-4" />
                  <span>Delete</span>
                </button>
              }
            </div>
          </div>
        </div>
      }
    </>
  )
}
