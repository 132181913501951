import { useMutation }                 from '@apollo/client'
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/solid'
import { format, isFuture, parseISO }  from 'date-fns'
import { useDispatch }                 from 'react-redux'
import { Link }                        from 'react-router-dom'
import TextBadge                       from '../../components/Badge/TextBadge'
import { LoadingEllipsis }             from '../../components/Loaders/Loaders'
import { setToasts, showToaster }      from '../../features/toaster/toasterSlice'
import { DELETE_NEWS }                 from '../../graphql/news'

export default function NewsTableItem( props: any ): JSX.Element {
  const { itemDetails: item, isNew, readIds, search, refetch } = props
  const dispatch: any                                          = useDispatch()
  const appCdnUrl: string                                      = process.env.REACT_APP_CDN + ( process.env.REACT_APP_CDN.slice(-1) === '/' ? '' : '/' )

  const [ deleteNews, { loading: deleting } ] = useMutation( DELETE_NEWS, {
    onCompleted: () => {
      dispatchMessage( 'success', 'News deleted' )
      refetch()
    }
  } )

  const dispatchMessage = ( type: string, message: string ) => {
    dispatch( setToasts( [ { id: '1', type, message } ] ) )
    dispatch( showToaster( true ) )
  }

  const handleDelete = ( e: any ) => {
    e.preventDefault()

    const confirmed = window.confirm( 'Are you sure you want to delete this News?' )
    if ( confirmed ) {
      deleteNews( { variables: { input: { unique_id: item.unique_id } } } )
    }
  }

  const dateInFuture = (): boolean => {
    return isFuture( parseISO( item.publish ) )
  }

  return (
    <>
      { deleting &&
        <tr className="relative h-32 border-b border-gray-table-border">
          <td>
            <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4" />
            </div>
          </td>
        </tr>
      }
      { !deleting &&
        <tr className="border-b border-gray-table-border">
          <td className="p-3">
            <Link to={ `/news/${ item.unique_id }` } className="text-blue-secondary hover:text-blue-secondary-darker hover:underline">
              {/*{ ( isNew === true ) && <TextBadge classes="mr-2" color="blue-secondary" text="white">New</TextBadge> }*/ }
              { item.title }
            </Link>
          </td>

          <td className="p-3">
            { item.draft && !search &&
              <TextBadge classes="ml-2" color="yellow-primary">Draft</TextBadge> }
            { !item.draft && !dateInFuture() && !search &&
              <TextBadge classes="ml-2" color="yellow-primary">Published</TextBadge>
            }
            { !item.draft && dateInFuture() && !search &&
              <TextBadge classes="ml-2" color="blue-secondary" text="white">Upcoming</TextBadge>
            }
          </td>

          <td className="p-3">
            { item.publish && format( parseISO( item.publish ), 'dd MMM yyyy' ) }
          </td>

          <td className="p-3">
            { item.featured_image_url &&
              <img className="h-32 w-auto" src={ appCdnUrl + item.featured_image_url } alt={ item.title } />
            }
          </td>

          <td className="p-3 text-center">
            <div className="flex">
              <Link to={ `/news/${ item.unique_id }/edit` }>
                <button type="button" className="flex p-0 mr-3 text-blue-secondary hover:text-blue-secondary-darker">
                  <PencilSquareIcon className="w-4 h-4" />
                </button>
              </Link>

              <button
                className="p-0 float-right hover:text-red-600"
                color="default"
                onClick={ handleDelete }
              >
                <TrashIcon className="w-4 h-4" />
              </button>
            </div>
          </td>
        </tr>
      }
    </>
  )
}
