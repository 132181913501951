import { Link } from "react-router-dom";
import { useDispatch }                 from 'react-redux'
import classNames                     from "classnames";
import { formatDistance  }            from 'date-fns'
import './NewsGridView.css'

export default function NewsGridViewItem( props: any ): JSX.Element {
  const appCdnUrl: string                                      = process.env.REACT_APP_CDN + ( process.env.REACT_APP_CDN.slice(-1) === '/' ? '' : '/' )
  const { itemDetails: item, featured, isNew, readIds, search, refetch } = props
  const currentDate = new Date();
  const authorName = item.user ? item.user.name : 'Head Office';
  // TODO: API server needs to grab the user's locationn !!!!

  return (
    <article className="NewsGridViewItem mt-3 inline-flex w-full bg-white rounded-md overflow-hidden relative">
      <section className={classNames('', {
        'flex lg:flex-row flex-col': featured
      })}>
        <div className="flex-1">
        { item.featured_image_url &&
              <img className="w-full" src={ appCdnUrl + item.featured_image_url } alt={ item.title } /> }
        </div>
        <div className="flex-1 p-3">
          <header className="mb-3">
            <h3 className={classNames("font-medium", {
              'md:text-2xl text-xl': featured
            })}>{ item.title }</h3>
            <p className="text-sm">{ item.headline }</p>
          </header>
          <div className="border-t py-3 text-sm">
            Published <b><time>{ item.publish && formatDistance(new Date(item.publish), currentDate, { addSuffix: true }) }</time></b> by <i> Katie Bowen, Head Office</i>

            <div className="mt-3">
              <Link to={ `/news/${ item.unique_id }` } className="text-white font-medium bg-blue-secondary hover:bg-blue-secondary-darker px-2 py-2 text-sm rounded-md inline-block">
                <span>Read more</span>
              </Link>
            </div>

          </div>
        </div>
      </section>
    </article>
  )
}