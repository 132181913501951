import { useSelector }                  from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Pagination                       from '../../components/Pagination/Pagination'
import NewsTableItem                    from './NewsTableItem'

export default function NewsTable( props: any ): JSX.Element {
  const { refetch }      = props
  const [ searchParams ] = useSearchParams()
  const navigate: any    = useNavigate()
  const readIds: any     = []
  const pagination: any  = useSelector( ( state: any ) => state.news.pagination )
  const news: any        = useSelector( ( state: any ) => state.news.data )

  const setCurrentPage = ( page: number = 1 ) => navigate( {
                                                             pathname: '/news',
                                                             search:   `?query=${ searchParams.get( 'query' ) }&page=${ page }`
                                                           } )

  return (
    <>
      { news.length < 1 && <p>There is no News yet.</p> }

      { news.length > 0 &&
        <table className="border border-gray-table-border w-full text-sm">
          <thead className="bg-gray-200 text-left">
            <tr>
              <th className="p-3">Title</th>
              <th className="p-3">Status</th>
              <th className="p-3">Publish Date</th>
              <th className="p-3">Featured Image</th>
              <th className="p-3"></th>
            </tr>
          </thead>

          <tbody>
            {
              news.map( ( item: any ) =>
                          <NewsTableItem
                            key={ item.id }
                            isNew={ readIds && ( readIds.length === 0 || !readIds.includes( item.id ) ) }
                            itemDetails={ item }
                            refetch={ refetch }
                          /> )
            }
          </tbody>
        </table>
      }

      { ( pagination?.totalPages > 0 ) &&
        <Pagination
          className="justify-content-center"
          currentPage={ pagination.page }
          totalCount={ pagination.totalResults }
          pageSize={ pagination.perPage }
          onPageChange={ ( page: any ) => setCurrentPage( page ) }
        />
      }
    </>
  )
}
