import { useLazyQuery }        from '@apollo/client'
import { format, parseISO }    from 'date-fns'
import { useEffect, useState } from 'react'
import { useSelector }         from 'react-redux'
import { useParams }           from 'react-router-dom'
import { LoadingEllipsis }     from '../../components/Loaders/Loaders'
import { GET_NEWS_BY_UID }     from '../../graphql/news'
import MainLayout              from '../../layouts/MainLayout'

export default function ViewNews(): JSX.Element {
  const { unique_id }     = useParams()
  const appCdnUrl: string = process.env.REACT_APP_CDN + ( process.env.REACT_APP_CDN.slice(-1) === '/' ? '' : '/' )
  const newsState: any    = useSelector( ( state: any ) => state.news.data ).find( ( item: any ): boolean => item?.unique_id == unique_id )
  const [ news, setNews ] = useState( newsState ?? {} )

  const [ fetchNewsByUID, { loading: fetchingNews, error: fetchingNewsError } ] = useLazyQuery( GET_NEWS_BY_UID, {
    onCompleted: ( { newsByUid } ) => setNews( newsByUid ?? {} )
  } )

  useEffect( (): void => {
    if ( !news.unique_id && !fetchingNews ) {
      fetchNewsByUID( { variables: { input: { unique_id } } } )
    }
  }, [ news.unique_id, fetchingNews ] )

  return (
    <MainLayout>
      <div className="max-w-screen-3xl mx-auto w-full">
        <div className="flex-grow lg:p-10 p-3 lg:space-y-8 space-y-3">

          <div className="single-news flex flex-col items-center w-full bg-white rounded-xl p-4 lg:p-5">
            { fetchingNews && <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4" /> }

            { !fetchingNews && news.unique_id &&
              <>
              <div className="flex flex-col gap-4 mb-4 lg:flex-row w-full">
                { news.featured_image_url &&
                  <img className="w-full h-auto lg:w-1/3" src={ appCdnUrl + news.featured_image_url } alt={ news.title } />
                }
                <div className="w-full lg:w-2/3">
                  <h1 className="mb-3 text-5xl font-light">{ news.title }</h1>
                  <p className="font-light text-base">{ news.headline }</p>
                  <hr className="my-3" />
                  { news.publish &&
                    <p className="text-gray-500 text-sm">
                        Published on { format( parseISO( news.publish ), 'do MMMM yyyy' ) } by <em>{ news.user.name }</em>
                    </p>
                  }
                </div>
              </div>

              <div className="body w-full" dangerouslySetInnerHTML={ { __html: news.body } } />
              </>
            }

            { fetchingNewsError &&
              <div className="flex flex-col">
                <b>Error occurred when fetching documents:</b> { ' ' }
                { fetchingNewsError.graphQLErrors.map( ( { message }, i: number ) => (
                  <span key={ i }>{ message }</span>
                ) ) }
              </div>
            }
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
