import { useLazyQuery }                       from '@apollo/client'
import { MagnifyingGlassIcon }                from '@heroicons/react/20/solid'
import { PlusIcon }                           from '@heroicons/react/24/solid'
import { debounce }                           from 'lodash-es'
import { useEffect }                          from 'react'
import { useDispatch, useSelector }           from 'react-redux'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { LoadingEllipsis }                    from '../../components/Loaders/Loaders'
import { setNewsData, setNewsRefetch }        from '../../features/news/newsSlice'
import { GET_NEWS }                           from '../../graphql/news'
import MainLayout                             from '../../layouts/MainLayout'
import NewsGridView                           from './GridView/NewsGridView'
import NewsTable                              from './NewsTable'

export default function News(): JSX.Element {
  const [ searchParams, setSearchParams ] = useSearchParams()
  const navigate: any                     = useNavigate()
  const dispatch: any                     = useDispatch()
  const urlParamQuery: string             = searchParams.get( 'query' )
  const urlParamPage: string              = searchParams.get( 'page' )
  const currentUser                       = useSelector( ( state: any ) => state.currentUser.user )
  const news: any                         = useSelector( ( state: any ) => state.news )
  const currentTenant: any                = useSelector( ( state: any ) => state.currentTenant.tenant )
  const bodyInfo: string                  = currentTenant?.pages?.news?.body ?? ''

  const [
          fetchNews,
          { loading: fetchingNews, error: fetchingNewsError, refetch }
        ] = useLazyQuery( GET_NEWS,
                          {
                            variables:                   {
                              input: {
                                page:  urlParamPage ? parseInt( urlParamPage ) : 1,
                                query: urlParamQuery ?? ''
                              }
                            },
                            notifyOnNetworkStatusChange: true,
                            onCompleted:                 ( { news } ) => dispatch( setNewsData( news ) )
                          } )

  useEffect( () => {
    if ( !news?.data.length && !fetchingNews ) {
      fetchNews()
    }

    if ( news?.refetch ) {
      refetch()
      dispatch( setNewsRefetch( false ) )
    }
  }, [ news ] )

  function fetchSearchedNews( page: number = 1, query: string = '' ): void
  {
    setSearchParams( ( params: any ) => {
      params.set( 'query', query )
      params.set( 'page', page )
      return params
    } )

    fetchNews()
  }

  const debouncedSearch: any = debounce( async ( searchTerms: any ): Promise<void> => fetchSearchedNews( 1, searchTerms ), 1000 )

  const handleSearch: any = ( e: any ): void => {
    const value: string = e.target.value?.trim()

    if ( value.length >= 3 && value !== urlParamQuery ) {
      debouncedSearch( value )
    }

    if ( value.length < 1 && urlParamQuery.length ) {
      navigate( '/news' )
    }
  }

  return (
    <MainLayout>
      <div className="max-w-screen-3xl mx-auto w-full">
        <div className="flex-grow lg:p-10 p-3 lg:space-y-8 space-y-3">

          <div className="row flex items-center w-full bg-white rounded-full p-2">
            <div className="col flex w-full">
              <div className="flex-1 bg-blue-subtle rounded-full flex items-center px-3 relative">
                <MagnifyingGlassIcon className="w-5 h-5" />

                <div className="w-full rounded-full">
                  <input
                    type="search"
                    placeholder="Search news..."
                    onChange={ ( e: any ) => handleSearch( e ) }
                    defaultValue={ urlParamQuery }
                    className="w-full bg-transparent focus:ring-transparent border-none pl-3 text-sm font-medium"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center w-full bg-white rounded-xl p-4 lg:p-5">
            <div className="flex w-full mb-4">
              <header className="mb-4 w-full">
                <div className="flex justify-between items-center mb-4">
                  <h1 className="sm:text-2xl text-xl font-bold">News</h1>
                  <Link to={ `/news/add` } className="flex items-center py-2 px-4 text-white bg-blue-secondary hover:bg-blue-secondary-darker rounded-full">
                    <PlusIcon className="w-5 h-5" />
                    <span>Add new</span>
                  </Link>
                </div>
                <p className="text-muted text-sm text-gray-500">{ bodyInfo }</p>
              </header>
            </div>

            <div className="w-full">
              {
                fetchingNews
                ? <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4" />
                : currentUser?.admin
                  ? <NewsTable refetch={ () => refetch() } />
                  : <NewsGridView refetch={ () => refetch() } />
              }
              {
                fetchingNewsError &&
                <div className="flex flex-col">
                  <b>Error occurred when fetching News:</b> { ' ' }
                  { fetchingNewsError.graphQLErrors.map( ( { message }, i: number ) => (
                    <span key={ i }>{ message }</span>
                  ) ) }
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
