import { gql } from '@apollo/client'

const UserDataFragment = gql`
    fragment UserData on User {
      id
      unique_id
      roi_id
      name
    }
`

const NewsFragment: any = gql`
    fragment NewsData on News {
      id
      old_id
      unique_id
      user {
        ...UserData
      }
      user_uid
      user_id
      slug
      title
      headline
      body
      featured_image_url
      draft
      publish
      expire
      notification_email
      notification_date
      created_at
      updated_at
    }
    ${ UserDataFragment }
`

const LinksFragment: any = gql`
    fragment LinksData on PaginatorLinks {
        totalResults
        totalPages
        page
        perPage
        prevPage
        nextPage
    }
`

export const GET_NEWS: any = gql`
  query News($input: QueryNewsInput!) {
      news(input: $input) {
          data {
            ...NewsData
          }
          links {
            ...LinksData
          }
      }
    }
    ${ NewsFragment }
    ${ LinksFragment }
`

export const GET_NEWS_BY_UID: any = gql`
  query news($input: QueryNewsByUidInput!) {
    newsByUid(input: $input) {
        ...NewsData
    }
  }
  ${ NewsFragment }
`

export const CREATE_NEWS: any = gql`
    mutation CreateNews($input: MutationCreateNewsInput!) {
      createNews(input: $input) {
        __typename
        ... on FieldError {
          field
          message
        }
        ... on MutationCreateNewsSuccess {
          data {
            ...NewsData
          }
        }
      }
    }
    ${ NewsFragment }
`

export const UPDATE_NEWS: any = gql`
    mutation UpdateNews($input: MutationUpdateNewsInput!) {
      updateNews(input: $input) {
        __typename
        ... on FieldError {
          field
          message
        }
        ... on MutationUpdateNewsSuccess {
          data {
            ...NewsData
          }
        }
      }
    }
    ${ NewsFragment }
`

export const DELETE_NEWS: any = gql`
    mutation DeleteNews($input: MutationDeleteNewsInput!) {
        deleteNews(input: $input) {
          __typename
          ... on FieldError {
            field
            message
          }
          ... on MutationDeleteNewsSuccess {
            data
          }
        }
    }
`

export const DELETE_NEWS_FEATURED_IMAGE: any = gql`
    mutation DeleteNewsFeaturedImage($input: MutationDeleteNewsFeaturedImageInput!) {
        deleteNewsFeaturedImage(input: $input) {
          __typename
          ... on FieldError {
            field
            message
          }
          ... on MutationDeleteNewsFeaturedImageSuccess {
            data
          }
        }
    }
`
