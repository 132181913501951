import { createSlice } from '@reduxjs/toolkit'

export const newsSlice = createSlice( {
  name:         'news',
  initialState: {
    data:       [],
    pagination: {},
    refetch:    false
  },
  reducers:     {
    setNewsData:    ( state, action ) => {
      state.data       = action.payload.data
      state.pagination = action.payload.links
    },
    setNewsRefetch: ( state, action ) => {
      state.refetch = action.payload
    }
  }
} )

export const { setNewsData, setNewsRefetch } = newsSlice.actions

export default newsSlice.reducer
