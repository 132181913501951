import React      from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
}                 from "react-router-dom";
import Home       from "./pages/Home";
// import {Calendar} from "./pages/Calendar/Calendar";
// import Message from "./pages/Message";
import Login from "./pages/auth/Login";
import PasswordReset from "./pages/auth/PasswordReset";
// import Components from "./pages/components";
// import { ContactsPage } from "./pages/Contacts";
// import { FoldersPage } from "./pages/Messages/Folders/Folders";
// import { NewEmailPage } from "./pages/Messages/New";
// import { AddCalendarEventPage } from "./pages/AddCalendarEventPage";
// import { DisplayCalendarEventPage } from "./pages/DisplayCalendarEventPage";
// import { MessageContact } from "./pages/MessageContact/MessageContact";
// import Integrations from "./pages/Integrations";
// import { Signatures, EditSignature } from './pages/Signatures';
// import { SetAsidesPage } from "./pages/SetAsides";
// import { ThreadPage } from "./pages/Messages/Message/ThreadPage";
// import { FilesPage } from './pages/FilesPage';
import Documents                from "./pages/Documents";
import { DocumentsDownloadLog } from "./pages/Downloads";
import NewSeniorLeaderNotification from "./pages/NewSeniorLeaderNotification/NewSeniorLeaderNotification";
import RegulatoryBodyNotificationForm from "./pages/RegulatoryBodyNotificationForm/RegulatoryBodyNotificationForm";
import FranchiseDevelopmentDayPresentation from './pages/static/FranchiseDevelopmentDayPresentation';
import { ForumPage } from "./pages/Forum/ForumPage";
import { ForumQuestion } from "./pages/Forum/ForumQuestion";
import FourFour from "./pages/404/404";
import News from "./pages/News/News";
import AddNews from "./pages/News/AddNews";
import EditNews from "./pages/News/EditNews";
import ViewNews from "./pages/News/ViewNews";
import DesignDock from "./pages/DesignDock/DesignDock";
import DesignDockError from "./pages/DesignDock/DesignDockError";
import BmPlatform from "./pages/BmPlatform/BmPlatform";
import BmPlatformFaq from "./pages/BmPlatform/BmPlatformFaq";
import BmPlatformHelpCentre from "./pages/BmPlatform/BmPlatformHelpCentre";
import Suppliers from "./pages/Suppliers/Suppliers";
import SuppliersFeedback from "./pages/Suppliers/SuppliersFeedback";
import HowToVideos from "./pages/HowToVideos/HowToVideos";
import NurserySupport from "./pages/NurserySupport/NurserySupport";
import "./App.css"

export default function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route exact path="/" element={ <Home/> } />
          {/* <Route exact path="/connected-integrations" element={ <Integrations/> } /> */}
          <Route exact path="/documents/*" element={ <Documents /> } />
          <Route exact path="/downloads/*" element={ <DocumentsDownloadLog /> } />

          <Route exact path="/new-senior-leader-notification" element={ <NewSeniorLeaderNotification /> } />
          <Route exact path="/regulatory-body-notification-form" element={ <RegulatoryBodyNotificationForm /> } />
          <Route exact path="/franchise-development-day-presentation" element={ <FranchiseDevelopmentDayPresentation /> } />

          <Route exact path="/forum" element={ <ForumPage /> } />
          <Route exact path="/forum/question/:uuid" element={ <ForumQuestion /> } />

          <Route exact path="/news" element={ <News /> } />
          <Route path="/news/:unique_id" element={ <ViewNews /> } />
          <Route path="/news/add" element={ <AddNews /> } />
          <Route path="/news/:unique_id/edit" element={ <EditNews /> } />

          <Route exact path="/design-dock" element={ <DesignDock /> } />
          <Route exact path="/design-dock-error" element={ <DesignDockError /> } />
          <Route exact path="/bm-platform" element={ <BmPlatform /> } />
          <Route exact path="/bm-platform/faq" element={ <BmPlatformFaq /> } />
          <Route exact path="/bm-platform/help-centre" element={ <BmPlatformHelpCentre /> } />

          <Route exact path="/suppliers" element={ <Suppliers /> } />
          <Route exact path="/suppliers/feedback" element={ <SuppliersFeedback /> } />
          <Route exact path="/how-to-videos" element={ <HowToVideos /> } />
          <Route exact path="/nursery-support" element={ <NurserySupport /> } />

          {/* <Route exact path="/messages" element={ <Message/> } />
          <Route exact path="/messages/new" element={ <NewEmailPage/> } />
          <Route exact path="/messages/signatures" element={ <Signatures /> } />
          <Route exact path="/messages/set-asides" element={ <SetAsidesPage /> } />
          <Route exact path="/messages/signatures/new" element={ <EditSignature isNew /> } />
          <Route exact path="/messages/signatures/:id/edit" element={ <EditSignature/> } />
          <Route exact path="/messages/contacts/:id" element={ <MessageContact/> } />
          <Route exact path="/messages/contacts/" element={ <ContactsPage/> } />
          <Route exact path="/messages/folders/:folderId/threads/:id" element={ <Message/> } />
          <Route exact path="/messages/folders/:folderId/" element={ <Message/> } />
          <Route exact path="/messages/threads/:id" element={ <ThreadPage /> } /> */}
          {/* <Route exact path="/messages/threads/:id/files" element={ <FilesPage /> } /> */}
          {/* <Route exact path="/messages/folders" element={ <FoldersPage/> } /> */}
          {/* <Route exact path="/calendar" element={ <Calendar /> } />
          <Route exact path="/events/:id" element={ <DisplayCalendarEventPage /> } />
          <Route exact path="/events/new" element={ <AddCalendarEventPage /> } /> */}
          <Route exact path="/login" element={ <Login />} />
          <Route exact path="/password/reset" element={ <PasswordReset />} />

          {/* <Route exact path="/components" element={ <Components/> } /> */}

          <Route path="*" element={<Navigate to="/404" />} />

          {/* 404 route */}
          <Route path="/404" element={<FourFour />} />


        </Routes>
      </div>
    </Router>
  );
}